<template>
  <div>
  <van-nav-bar title="乘梯安全知识" left-text="返回" left-arrow @click-left="back()" :fixed="true" :placeholder="true"/>

<div style="background-color: rgb(245, 245, 245);">
  <!-- 左对齐 -->
  <div v-for="item in allIssue">
  <div>
    <van-row type="flex">
      <van-col span="16" class="content" style="background-color: rgb(255, 255, 255);font-weight: bold;">{{item.issue}}</van-col>
    </van-row>
  </div>

  <!-- 右对齐 -->
  <div>
    <van-row type="flex" justify="end">
      <van-col span="16" class="content" style="background-color: rgb(149, 236, 105);" v-html="item.reply"></van-col>
    </van-row>
  </div>
  </div>
  </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      allIssue: [
        {
          issue: '问：被困电梯该怎么办？',
          reply: '答：1、保持镇定，稳定情绪，不要自行向电梯外翻爬。<br>' +
        '2、拨打96111电梯应急救援电话求助；或拨打电梯轿厢内维保单位、使用管理单位的24小时应急电话求助；或通过轿厢内的紧急告警装置进行求助；如遇轿厢内无信号可拨打119求助。<br>' +
            '3、向外发出求助信号后，等待专业人员救援。<br>' +
        '4、应在专业人员的指导下采取相关措施，切不可采取撬门、扒门等错误的自救行动。'
        },
        {
          issue: '问：乘坐电梯时，要注意哪些？',
          reply: '答：1、学龄前儿童、高龄老人或无民事行为能力的人员应在监护人陪同下搭乘电梯。<br>2、请勿搭乘没有粘贴电梯使用标志、超过检验合格有效期的电梯。<br>3、严禁在电梯内外拍、踢、撞击、倚靠、强扒电梯门和摇晃打闹。' +
        '<br>4、禁止在轿门和层门之间逗留、用身体阻挡电梯门。<br>5、严禁超载使用电梯。' +
        '<br>6、切勿乘坐晃动剧烈、异响、轿厢内无照明、电梯门关不上或未关门就运行、轿厢地板与楼层不水平等状态异常的电梯。<br>7、遇火灾、地震或其它灾害时，请勿乘坐电梯。'
        },
        {
          issue: '问：电梯下坠情况的最佳保护措施是什么？',
          reply: '答：1.快速把每一层的按键都按下。<br>2.如果电梯内有把手，一只手紧握把手固定位置，防止摔倒。 <br>3.整个背部与头部紧贴不靠门的内墙，呈一直线，' +
          '运用墙体作为脊椎防护。 <br>4.膝盖呈弯曲姿势，借膝盖弯曲来承受重击压力。 <br>5.把脚跟提起，呈踮脚姿势。'
        },
        {
          issue: '问：被困电梯时的危险举动有哪些？',
          reply: '答：被困乘客强行撬门或者打开轿箱顶部的天窗逃生,这样做是不安全行为。' +
        '电梯在出现故障时，门的电气回路方面可能会发生失灵的情况，如果在强行扒门时电梯异常启动，很容易会发生剪切，造成人身伤害。由于同样的原因，被困乘客也不要自己从天窗爬出。' +
        '正确的做法是乘客在轿厢内耐心等待专业救援人员到来救援。乘客千万不可做踢门、撬门、扒门、跳跃、攀爬等过激动作，这都是很危险的。电梯开门时如果不在平层位置，此时试图跳、' +
        '爬出轿厢同样危险，可能会坠入电梯井道。'
        },
        {
          issue: '问：电梯发生故障时会有坠落和窒息的危险吗？',
          reply: '答：1、电梯从设计方面是相当安全的，不要担心连接轿厢的钢丝绳会折断，因为国家对电梯用的钢丝绳有专门的规定和要求，' +
            '钢丝绳的承载能力大大高于电梯的载重量，每部电梯都配有3根及以上数量的钢丝绳，因此电梯具有很高的安全性。' +
            '<br>2.电梯还有一套防坠落系统，包括限速器，安全钳，以及底部的缓冲器。一旦发现电梯超速下降，限速器首先会让电梯驱动主机停止运转。' +
            '如果主机仍然没有停止，限速器就会提升安全钳使之夹紧导轨，强制轿厢停滞在导轨上，另外在一定速度内如果直接撞击到缓冲器上，' +
            '轿厢也会停下来。轿厢不管通过那种方式停下来，都不会对人造成很大的冲击。' +
            '<br>3.在狭窄闷热的电梯里，乘客可能担心会受困后会窒息而死，国家标准对电梯的通风有严格的规定，即使电梯困人时通风系统也是正常通风的。' +
            '另外，电梯有很多活动的部件，比如说一些连接的位置，比如轿壁和轿顶连接处都有缝隙，这些缝隙一般来讲足够人的呼吸需要。' +
            '因此，一旦出现困梯情况，请不要惊慌并及时报警，安心在轿厢等待救援。'
        }
      ]
    }
  },
  methods: {
    back () {
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.content {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Microsoft YaHei";
}
::v-deep .van-nav-bar__content {
    background: url(../../assets/bj.png) no-repeat;
}
::v-deep .van-nav-bar__title {
    color: #FFFFFF;
}
::v-deep .van-nav-bar__text {
    color: #FFFFFF;
}
::v-deep .van-nav-bar__arrow {
    color: #FFFFFF;
}
</style>
